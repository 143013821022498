// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';

import '@remark/components/build/main.css';
import * as Components from '@remark/components';
import '../styles/index.scss';

require('./cocoon');

Rails.start();
Turbolinks.start();
ActiveStorage.start();
/* Register module components */
const app = (window.app = global.app = {});
Object.keys(Components).forEach((component) => {
  if (Components[component].displayName) {
    app[Components[component].displayName] = Components[component];
  }
});
/* Register local components */
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

/* Load enabled after react components are loaded */
require('./enabler');

/* Register fonts */
document.addEventListener('DOMContentLoaded', () => {
  if (Components._breakpointsInit) {
    Components._breakpointsInit();
  }
  if (Components._webFontLoader) {
    Components._webFontLoader('google', null, [
      'Montserrat:400,500,600,700',
      'Roboto:400,500,700',
      'Open Sans:400,500,600,700'
    ]);
  }

});

function QuestionnaireTrigger() {
  const url = '/users/prolong';
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', url);
  xhr.setRequestHeader('X-CSRF-Token', window._token);
  xhr.send({});
}

window.Rails = Rails;

window.addEventListener('questionnaire-trigger', QuestionnaireTrigger);

window.launchModal = function (content) {
  if (document.querySelector('.portal-node')) {
    document.querySelector('.portal-node').remove();
  }

  const container = document.createElement('div');
  container.innerHTML = content;
  document.body.appendChild(container);

  ReactRailsUJS.mountComponents(container);
  Rails.refreshCSRFTokens();

  // container.remove();
};

function afterCocoon(customEvent) {
  ReactRailsUJS.mountComponents(customEvent.target.parentNode);
}

document.addEventListener('cocoon:after-insert', afterCocoon);
window.replaceInnards = function (nodeId, content) {
  const domNode = document.getElementById(nodeId)
  domNode.innerHTML = content
  ReactRailsUJS.mountComponents(domNode)
}

/**
 * Monkey patch Turbolinks to render 403, 404 & 500 normally
 * See https://github.com/turbolinks/turbolinks/issues/179#issuecomment-289287888
 * See https://remarkgroup.atlassian.net/browse/VPD-1853
 */

window.Turbolinks.HttpRequest.prototype.requestLoaded = function() {
  return this.endRequest(function() {
    var code = this.xhr.status;
    if (200 <= code && code < 300 ||
        code === 403 || code === 404 || code === 500) {
      this.delegate.requestCompletedWithResponse(
          this.xhr.responseText,
          this.xhr.getResponseHeader("Turbolinks-Location"));
    } else {
      this.failed = true;
      this.delegate.requestFailedWithStatusCode(code, this.xhr.responseText);
    }
  }.bind(this));
};
