/**
 * Enable a button when some css selector results in items
 * 
 * Example:
 * <input type="checkbox" name="[checkbox_name][1]" />
 * <input type="checkbox" name="[checkbox_name][2]" />
 * <button data-enabler="[name*='[checkbox_name]']" data-enable-if=":checked" disabled>Do it!</button>
 *  
 */

const enabler = () => {
  const buttons = document.querySelectorAll('[data-enabler]');
  buttons.forEach((button) => {
    const target = button.getAttribute('data-enabler');
    const when = button.getAttribute('data-enable-if');
    const enablers = document.querySelectorAll(target);

    enablers.forEach((enabler) => {
      enabler.addEventListener('change', () => {
        if (document.querySelectorAll(target + when).length > 0) {
          button.removeAttribute('disabled');
        } else {
          button.setAttribute('disabled', true);
        }
      });
    });
  });
}

document.addEventListener('DOMContentLoaded', enabler);
document.addEventListener('turbolinks:load', enabler);
document.addEventListener('page:load', enabler);
